const {__, _x, _n, _nx} = wp.i18n;

//Bootstrap component list: Alert, BaseComponent, Button, Carousel, Collapse, Dropdown, Modal, Offcanvas, Popover, ScrollSpy, Tab, Tooltip, Toast
import {Collapse} from 'bootstrap';
import SimpleLightbox from "simplelightbox";

require('./utilities/class_enlarge.js');
require('./utilities/class_scroll.js');
require('./utilities/class_count.js');
require('./utilities/pdw_mobile_menu.js');
require('./utilities/pdw_modal_search.js');
require('./utilities/pdw_slide.js');
require('./utilities/pdw_carousel.js');
require('./utilities/pdw_slider.js');
require('./utilities/pdw_hide_link.js');
require('./utilities/pdw_in_view.js');
require('./utilities/pdw_trigger_splide.js');
//require('./utilities/pdw_woocommerce.js');

//Lightbox
let lightbox = new SimpleLightbox('.pdw-lightbox a', { /* options */});

//Drilldown menu
window.addEventListener("DOMContentLoaded", (event) => {
    document.querySelectorAll('ul.mobile-drilldown-menu li.menu-item-has-children > a').forEach(element => {
        //Create previous button
        let previous = document.createElement('span');
        element.nextElementSibling.prepend(previous);
        previous.classList.add("pdw-submenu-close");
        previous.innerHTML = element.innerHTML;
        previous.addEventListener("click", (event) => {
            event.preventDefault();
            event.target.parentElement.parentElement.classList.remove("open");
        });
        //Create open button
        let span = document.createElement('span');
        element.appendChild(span);
        span.classList.add("pdw-submenu-open");
        span.addEventListener("click", (event) => {
            event.preventDefault();
            event.target.parentElement.parentElement.classList.add("open");
            document.getElementById('pdw-mobile-menu').scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        });
    });
});