document.addEventListener("DOMContentLoaded", function() {
    var scrollElement = document.scrollingElement;
    var scrollElementPos = scrollElement.scrollTop;
    var header = document.getElementById("watchScroll--header");


    document.addEventListener('scroll', function() {
        scrollElementPos = scrollElement.scrollTop;

        if(scrollElementPos >= 1){
            header.classList.add("scrolled");
        }
        
        else {
            header.classList.remove("scrolled");
        }

    });
});