import Splide from '@splidejs/splide';
//DOM loaded
document.addEventListener("DOMContentLoaded", () => {
    //Slider init
    let slider = document.querySelectorAll('.pdw-slider');
    if (slider.length) {
        let conf = {
            type: 'fade',
            focus: 'center',
            perPage: 1,
            perMove: 1,
            gap: 0,
            autoplay: 5000,
            pauseOnHover: true
        }
        const splideSliders = [];
        slider.forEach(item => {
            splideSliders.push(new Splide(item, conf).mount());
        });

        //Observe vc row resize
        const row = document.querySelector('div[data-vc-full-width="true"]');
        if (row) {
            const observer = new MutationObserver((event) => {
                event.forEach((mr) => {
                    if (mr.attributeName === 'data-vc-full-width-init') {
                        splideSliders.forEach(splideSlider => {
                            splideSlider.mount();
                        });
                    }
                });
            });
            const config = {
                attributes: true,
                childList: false,
                subtree: false,
            };
            observer.observe(row, config);
        }
    }
});