//Function to change modal search visibility
const changeSearchVisibility = (action, id = 'pdw-search') => {
    let el = document.getElementById(id);
    if (action === 'open') {
        el.classList.add('show');
        el.querySelector("#search").focus();
    } else if (action === 'close') {
        el.classList.remove('show');
    } else if (action === 'toggle') {
        el.classList.toggle('show');
        if (el.classList.contains('show')) {
            el.querySelector('#search').focus();
        }
    }
}

//DOM loaded
document.addEventListener("DOMContentLoaded", () => {

    //Listen to close button
    document.querySelectorAll('.pdw-search-close').forEach((el) => {
        el.addEventListener('click', () => {
            changeSearchVisibility('close');
        });
    });

    //Listen to open button
    document.querySelectorAll('.pdw-search-open').forEach((el) => {
        el.addEventListener('click', () => {
            changeSearchVisibility('open');
        });
    });

    //Listen to toggle button
    document.querySelectorAll('.pdw-search-toggler').forEach((el) => {
        el.addEventListener('click', () => {
            changeSearchVisibility('toggle');
        });
    });

});