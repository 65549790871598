function scrollRevealAnimation(selector, initialClass, revealClass) {
    let elements = document.querySelectorAll(selector);

    function addClass(element, className) {
        element.classList.add(className);
    }

    function removeClass(element, className) {
        element.classList.remove(initialClass);
    }

    function handleScroll() {
        elements.forEach(function (element) {
            let rect = element.getBoundingClientRect();
            let windowHeight = window.innerHeight || document.documentElement.clientHeight;

            if (rect.top < windowHeight) {
                addClass(element, revealClass);
                removeClass(element, initialClass);
            } else {
                element.classList.remove(revealClass);
            }
        });
    }

    elements.forEach(function (element) {
        addClass(element, initialClass);
    });

    window.addEventListener('scroll', handleScroll);
    handleScroll();
}

document.addEventListener('DOMContentLoaded', function () {
    scrollRevealAnimation('.bounce-in-bottom', 'invisible', 'is-animate');
    scrollRevealAnimation('.slide-top', 'invisible', 'is-animate');
});
