import Splide from '@splidejs/splide';
//DOM loaded
document.addEventListener("DOMContentLoaded", () => {
    if (document.querySelector('.splide-valeur')) {
        new Splide('.splide-valeur', {
            type: 'loop',
            perPage: 3,
            breakpoints: {
                768: {
                    perPage: 1,
                },
            },
            autoplay: true,
            interval: 5000,
            flickMaxPages: 3,
            updateOnMove: true,
            pagination: false,
            throttle: 300,
        }).mount();
    }
});
